"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function allSettled(arrayOrPromise) {
  return Promise.resolve(arrayOrPromise).then(promises => Promise.all(promises.map(promise => Promise.resolve(promise).then(value => ({
    status: 'fulfilled',
    value
  })).catch(reason => ({
    status: 'rejected',
    reason
  })))));
}
var _default = exports.default = allSettled;
module.exports = exports.default;