"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
function _default(Component, fallback = 'Component') {
  if (!Component) {
    return fallback;
  }
  return Component.displayName || Component.name || Component.type && (Component.type.displayName || Component.type.name) || fallback;
}
module.exports = exports.default;