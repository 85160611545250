"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function defer() {
  //initilize the functions as dead functions
  let resolve = () => {};
  let reject = () => {};
  const promise = new Promise((fst, snd) => {
    resolve = fst;
    reject = snd;
  });
  return {
    promise,
    resolve,
    reject
  };
}
var _default = exports.default = defer;
module.exports = exports.default;