"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function isThenable(obj) {
  return !!(obj && typeof obj.then === 'function');
}
var _default = exports.default = isThenable;
module.exports = exports.default;