"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function delay(val, ms) {
  if (ms === undefined) {
    ms = val;
    val = undefined;
  }
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(val);
    }, ms);
  });
}
var _default = exports.default = delay;
module.exports = exports.default;