"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.warn = exports.default = void 0;
var _enviro = _interopRequireDefault(require("enviro"));
const logShown = {};
const shouldLog = key => {
  return !_enviro.default.deployed() && (key == null || !logShown[key]);
};
const markAsShown = key => {
  if (key) {
    logShown[key] = true;
  }
};
const warn = ({
  message = '',
  key,
  url
}) => {
  if (url) {
    message += `\nFor details, see: ${url}`;
  }
  if (shouldLog(key)) {
    console.warn(message);
    markAsShown(key);
  }
};
exports.warn = warn;
var _default = exports.default = {
  warn
};